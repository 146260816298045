<template>
	<PreOrderPickupAtStoreForm :pre-order-id="$route.params.id" />
</template>

<script>
import PreOrderPickupAtStoreForm from '@/components/PreOrderPickupAtStoreForm.vue';

export default {
	name: 'PreOrderPickupAtStoreCreate',
	components: {
		PreOrderPickupAtStoreForm,
	},
};
</script>
